import numbro from 'numbro';

import { auctionTypes, bondTypes } from 'shared/const/auctions';

export const getUnitStructureDescription = (unitStructure, t) => {
  return `${t(`common.${unitStructure.type}`)} (${unitStructure.symbol})`;
};

export const getUnitsStructureDescription = (unitStructures, t) => {
  const getLocalizedUnitStructureDescription = (unitStructure) => getUnitStructureDescription(unitStructure, t);
  return unitStructures.map(getLocalizedUnitStructureDescription).join(', ');
};

export const buildAuctionTitleFromAuction = (auction, t) => {
  const { issuedCompanyName, unitStructures } = auction;
  return buildAuctionTitleGeneric({ issuedCompanyName, unitStructures, t });
};

export const buildAuctionTitleGeneric = ({ issuedCompanyName, unitStructures, t }) => {
  const unitStructuresDesc = getUnitsStructureDescription(unitStructures, t);

  const result = `${issuedCompanyName} - ${unitStructuresDesc}`;
  return result;
};

export const buildAuctionTitleFromOffer = (offer, t) => {
  const { auctionDetails } = offer.initiation;
  const { issuedCompanyName, unitStructures } = auctionDetails;
  return buildAuctionTitleGeneric({ issuedCompanyName, unitStructures, t });
};

export const buildAuctionTitle = ({ auction, t, offer }) => {
  if (auction) return buildAuctionTitleFromAuction(auction, t);
  if (offer) return buildAuctionTitleFromOffer(offer, t);
  return '';
};

export const getLocalizedAuctionType = (type, t) => {
  switch (type) {
    case auctionTypes.INTEREST:
      return t('common.interest');
    case auctionTypes.PRICE:
      return t('common.price');
    case auctionTypes.GAP:
      return t('common.gap');
    default:
      return '';
  }
};

export const getLocalizedAuctionTypeFromOffer = (offer, t) => {
  const auction = offer.initiation.auctionDetails;
  return getLocalizedAuctionType(auction.type, t);
};

export const getFormattedLimit = (limit, type) => {
  switch (type) {
    case auctionTypes.INTEREST:
    case auctionTypes.GAP:
      return numbro(limit).formatCurrency({
        thousandSeparated: true,
        currencySymbol: '%',
        currencyPosition: 'postfix',
      });

    case auctionTypes.PRICE:
      return numbro(limit).format('0,0.0$');
    default:
      return limit;
  }
};

export const getFormattedLimitForOffer = (offer, limit) => {
  const auction = offer.initiation.auctionDetails;
  return getFormattedLimit(limit, auction.type);
};

export const getFormattedMaxPercentageFromAuction = (maxPercentageFromAuction) => {
  return numbro(maxPercentageFromAuction).formatCurrency({
    thousandSeparated: true,
    currencySymbol: '%',
    currencyPosition: 'postfix',
  });
};

export const getAuctionTypesFromOffer = (offer) => {
  const auction = offer.initiation.auctionDetails;

  return auction.unitStructures.map((unitStructure) => unitStructure.type);
};

export const isBondAuction = (offer) => {
  const unitStructureTypes = getAuctionTypesFromOffer(offer);

  return unitStructureTypes.length === 1 && unitStructureTypes.some((type) => bondTypes.includes(type));
};
