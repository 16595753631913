import numbro from 'numbro';

import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';

import { getFormattedLimit } from '../auctions';

export const formatBidValue = (value, bidType, offer) => {
  const auctionType = offer?.initiation?.auctionDetails?.type;
  switch (bidType) {
    case BID_INPUT_FIELDS.LIMIT:
      return getFormattedLimit(value, auctionType);
    case BID_INPUT_FIELDS.AMOUNT:
      return numbro(value).format({
        thousandSeparated: true,
        mantissa: 0,
      });
    default:
      return value;
  }
};
