import { useMutation } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/components/offers/bids-and-accounts';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { updateOfferScopeAndDividedAccounts, updateOfferScopeAndUnitedAccounts } from 'shared/apis';
import { AccountsStructures } from 'shared/const/accountsStructures';
import { ALERT_TYPES } from 'shared/const/alerts';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useOfferStateUtils } from 'hooks/offers';

import { generateBidsAndAccountsDefaultValues } from '../utils';

import { buildDividedAccountsBidTableFromPayload, prepareDividedAccountsForMutation, prepareUnitedAccountsForMutation } from './utils';

export const useBidsAndAccountsForm = ({ checkSplitsLimitsMismatch, checkWarnings }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offer, setOffer, scopeAndAccountsErrors, setOnProceed, setIsLoadingFormSubmit, offerDataLayer } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { isOfferComplete } = useOfferStateUtils({ offer });

  /* FORM INITIALIZATION */
  const defaultValues = generateBidsAndAccountsDefaultValues(offer);
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const handleUpdateOfferScopeAndAccountsError = useCallback(
    (error) => {
      const errorName = getErrorNameFromErrorResponse(error);
      switch (errorName) {
        case ERROR_CODES.INVALID_GAP:
          addAlert({
            title: t('components.bidsAndAccounts.errors.invalidGap.title'),
            description: t('components.bidsAndAccounts.errors.invalidGap.description'),
            error,
            type: ALERT_TYPES.ERROR,
          });
          break;
        default:
          addAlert(errorAlertBuilder.bug(error));
          break;
      }
    },
    [t, addAlert],
  );

  const onSuccess = useCallback(
    (data) => {
      setOffer(data);
      navigate('/user/offers/create/limitations-and-contacts/' + offer._id);
    },
    [navigate, offer, setOffer],
  );

  /* UNITED ACCOUNTS SUBMISSION */
  const { mutate: updateOfferScopeAndUnitedAccountsMutation, isPending: scopeAndUnitedAccountsUpdating } = useMutation({
    mutationKey: 'updateOfferScopeAndUnitedAccounts',
    mutationFn: updateOfferScopeAndUnitedAccounts,
    onSuccess,
    onError: handleUpdateOfferScopeAndAccountsError,
  });

  /* DIVIDED ACCOUNTS SUBMISSION */
  const { mutate: updateOfferScopeAndDividedAccountsMutation } = useMutation({
    mutationKey: 'updateOfferScopeAndDividedAccounts',
    mutationFn: updateOfferScopeAndDividedAccounts,
    onSuccess,
    onError: handleUpdateOfferScopeAndAccountsError,
  });

  const formStateInvalid = useMemo(() => {
    const formErrors = Object.values(scopeAndAccountsErrors).filter((fieldErrorsArray) => fieldErrorsArray?.length);
    const formStateInvalid = formErrors.length > 0;
    return formStateInvalid;
  }, [scopeAndAccountsErrors]);

  const buildPayloadBidsAndSubmitFn = useCallback(
    (data) => {
      let payload, submitFn, bids;
      switch (data.accountsStructure) {
        case AccountsStructures.DIVIDED:
          payload = prepareDividedAccountsForMutation(offer, data);
          bids = cloneDeep(buildDividedAccountsBidTableFromPayload(payload));
          submitFn = updateOfferScopeAndDividedAccountsMutation;
          break;
        case AccountsStructures.UNITED:
          payload = prepareUnitedAccountsForMutation(offer, data);
          bids = cloneDeep(payload.bids);
          submitFn = updateOfferScopeAndUnitedAccountsMutation;
          break;
        default:
          console.error('no account structure is set');
          return;
      }

      return { payload, submitFn, bids };
    },
    [offer, updateOfferScopeAndDividedAccountsMutation, updateOfferScopeAndUnitedAccountsMutation],
  );

  /* FORM SUBMISSION AND MUTATION*/
  const onSubmit = useCallback(
    async (data) => {
      if (isOfferComplete) {
        navigate(`/user/offers/create/limitations-and-contacts/${offer._id}`);
        return;
      }

      if (formStateInvalid) {
        addAlert(errorAlertBuilder.bug());
        return;
      }

      const { payload, submitFn, bids } = buildPayloadBidsAndSubmitFn(data);

      let shouldProceed = true;

      shouldProceed = await checkSplitsLimitsMismatch(payload, bids);
      if (!shouldProceed) return;

      shouldProceed = await checkWarnings(bids);
      if (!shouldProceed) return;

      // finally submit the payload
      payload.dataLayer = offerDataLayer;
      submitFn(payload);
    },
    [
      isOfferComplete,
      formStateInvalid,
      buildPayloadBidsAndSubmitFn,
      checkSplitsLimitsMismatch,
      checkWarnings,
      offerDataLayer,
      navigate,
      offer._id,
      addAlert,
    ],
  );

  useEffect(() => {
    setOnProceed(() => handleSubmit(onSubmit));
  }, [handleSubmit, onSubmit, setOnProceed]);

  useEffect(() => {
    setIsLoadingFormSubmit(scopeAndUnitedAccountsUpdating);
    return () => {
      setIsLoadingFormSubmit(false);
    };
  }, [setIsLoadingFormSubmit, scopeAndUnitedAccountsUpdating]);

  return {
    methods,
  };
};
