import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { WarningModalContent } from './WarningModalContent';

export const WarningModal = ({ config: { show }, handleConfirm, handleCancel, data }) => {
  const { t } = useTranslation();

  return (
    <Modal size="xl" show={show} onHide={handleCancel} centered dialogClassName="justify-center" contentClassName="w-auto text-danger">
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>{t('components.WarningModal.title')}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WarningModalContent data={data} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleCancel}>
          <span className="text-black">{t('components.WarningModal.cancel')}</span>
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          {t('components.WarningModal.proceed')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
