import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import { WinningOffersTable } from './WinningOffersTable';

export const WinningOffersModal = ({ show, setShow, publicOffering }) => {
  const title = useMemo(() => {
    if (!publicOffering) return '';
    const securityId = publicOffering.securityId ? ` (${publicOffering.securityId})` : '';
    return `${publicOffering.securityName}${securityId}, ${dayjs(publicOffering.auctionDate).format('DD.MM.YYYY')}`;
  }, [publicOffering]);

  return (
    <Modal onHide={() => setShow(false)} centered show={show} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WinningOffersTable winningOffers={publicOffering.winningOffersList} publicOffering={publicOffering} />
      </Modal.Body>
    </Modal>
  );
};
