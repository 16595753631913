import dayjs from 'dayjs';
import compact from 'lodash/compact';
import some from 'lodash/some';
import numbro from 'numbro';

import { CommonLink } from 'components/common/CommonLink';

import { WinningOffersCell } from './winningOffers/WinningOffersCell';

// ensure empty values are not displayed
const wrapper = (value, formatCb) => {
  return value ? formatCb(value) : '';
};

export const columns = compact([
  {
    Header: 'securityName',
    accessor: 'securityName',
  },
  {
    Header: 'securityId',
    accessor: 'securityId',
  },
  {
    Header: 'mosadiAuctionDate',
    accessor: 'instAuctionDate',
    Cell: (row) => wrapper(row.instAuctionDate, (value) => dayjs(value).format('DD.MM.YYYY')),
  },
  {
    Header: 'auctionDate',
    accessor: 'auctionDate',
    Cell: (row) => wrapper(row.auctionDate, (value) => dayjs(value).format('DD.MM.YYYY')),
  },
  {
    Header: 'winningOffers',
    accessor: 'winningOffersList',
    noSort: true,
    Cell: (row) => {
      const { winningOffersList } = row;
      if (!some(winningOffersList)) {
        return '';
      }

      return <WinningOffersCell publicOffering={row} />;
    },
  },
  {
    Header: 'sector',
    accessor: 'sector',
  },
  {
    Header: 'linkage',
    accessor: 'linkage',
  },
  {
    Header: 'shelfLink',
    accessor: 'shelfLink',
    Cell: (row) => {
      const link = row.shelfLink;
      return <CommonLink link={link} />;
    },
    noSort: true,
  },
  {
    Header: 'duration',
    accessor: 'duration',

    Cell: (row) => wrapper(row.duration, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiClosingLimit',
    accessor: 'instClosingLimit',

    Cell: (row) => wrapper(row.instClosingLimit, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiClosingYield',
    accessor: 'instClosingYield',

    Cell: (row) => wrapper(row.instClosingYield, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'mosadiClosingMargin',
    accessor: 'instClosingMargin',

    Cell: (row) => wrapper(row.instClosingMargin, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'commitmentFee',
    accessor: 'instCommitmentFee',
    Cell: (row) => wrapper(row.instCommitmentFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'mosadiOpeningLimit',
    accessor: 'instOpeningLimit',

    Cell: (row) => wrapper(row.instOpeningLimit, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiOpeningYield',
    accessor: 'instOpeningYield',

    Cell: (row) => wrapper(row.instOpeningYield, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'mosadiOpeningMargin',
    accessor: 'instOpeningMargin',

    Cell: (row) => wrapper(row.instOpeningMargin, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'mosadiPriceDemand',
    accessor: 'instDemandIls',

    Cell: (row) => wrapper(row.instDemandIls, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'mosadiUnitsDemand',
    accessor: 'instDemandUnits',

    Cell: (row) => wrapper(row.instDemandUnits, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'mosadiClosingPrice',
    accessor: 'instClosingIls',

    Cell: (row) => wrapper(row.instClosingIls, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'mosadiClosingUnits',
    accessor: 'instClosingUnits',

    Cell: (row) => wrapper(row.instClosingUnits, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicAuctionDate',
    accessor: 'publicAuctionDate',
    Cell: (row) => wrapper(row.publicAuctionDate, (value) => dayjs(value).format('DD.MM.YYYY')),
  },
  {
    Header: 'publicOfferedUnits',
    accessor: 'publicOfferedUnits',

    Cell: (row) => wrapper(row.publicOfferedUnits, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicClosingUnits',
    accessor: 'publicClosingUnits',

    Cell: (row) => wrapper(row.publicClosingUnits, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicClosingLimit',
    accessor: 'publicClosingLimit',

    Cell: (row) => wrapper(row.publicClosingLimit, (value) => numbro(value).format('0,0')),
  },
  {
    Header: 'publicClosingPrice',
    accessor: 'publicClosingILS',

    Cell: (row) => wrapper(row.publicClosingILS, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'leadUnderwriter',
    accessor: 'leadUnderwriter',
    noSort: true,
  },
  {
    Header: 'subUnderwriters',
    accessor: 'subUnderwriters',
    noSort: true,
  },
  {
    Header: 'advisors',
    accessor: 'advisors',
    noSort: true,
  },
  {
    Header: 'advisorFee',
    accessor: 'advisorFee',

    Cell: (row) => wrapper(row.advisorFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'distributionFee',
    accessor: 'distributionFee',

    Cell: (row) => wrapper(row.distributionFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'successFee',
    accessor: 'successFee',

    Cell: (row) => wrapper(row.successFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'underwritingFee',
    accessor: 'underwritingFee',

    Cell: (row) => wrapper(row.underwritingFee, (value) => numbro(value / 100).format('0.00%')),
  },
  {
    Header: 'auctionFee',
    accessor: 'publicAuctionFee',

    Cell: (row) => wrapper(row.publicAuctionFee, (value) => numbro(value).format('0,0$')),
  },
  {
    Header: 'auctionType',
    accessor: 'auctionType',
    noSort: true,
  },
  {
    Header: 'issuer',
    accessor: 'issuer',
  },
  {
    Header: 'offeringType',
    accessor: 'offeringType',
    noSort: true,
  },
  {
    Header: 'rating',
    accessor: 'rating',
    noSort: true,
  },
  {
    Header: 'covenant',
    accessor: 'covenant',
    noSort: true,
  },
  {
    Header: 'notes',
    accessor: 'notes',
    noSort: true,
  },
]);
