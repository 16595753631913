import numbro from 'numbro';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getFormattedLimit, getLocalizedAuctionType } from 'shared/utils/auctions';

export const ValueWarning = ({ data }) => {
  const { t } = useTranslation();
  const { type, hasAboveThresholdBids, hasUnderLimitBids, highValueBids, lowValueBids } = data;

  const renderBids = useCallback(
    (bids) => {
      return bids?.map((bid, index) => {
        return (
          <li key={index}>
            {getLocalizedAuctionType(type, t)} {getFormattedLimit(bid.limit, type)} - {numbro(bid.value).format('0.0a$')}
          </li>
        );
      });
    },
    [type, t],
  );

  const warningType = useMemo(() => {
    if (hasAboveThresholdBids && hasUnderLimitBids) {
      return 'HighAndLowValueWarning';
    } else if (hasAboveThresholdBids) {
      return 'HighValueWarning';
    } else {
      return 'LowValueWarning';
    }
  }, [hasAboveThresholdBids, hasUnderLimitBids]);

  const generateWarning = useCallback(
    (bids, tPrefix) => {
      return bids?.length > 0 ? (
        <>
          <p>{t(`components.ValueWarning.${tPrefix}.message`)}:</p>
          <ul className="list-disc list-inside">{renderBids(bids)}</ul>
        </>
      ) : null;
    },
    [renderBids, t],
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <p className="underline">{t(`components.ValueWarning.${warningType}.title`)}</p>
      {generateWarning(highValueBids, 'HighValueWarning')}
      {generateWarning(lowValueBids, 'LowValueWarning')}
    </>
  );
};
