import { MaxLimitWarning } from './MaxLimitWarning';
import { OutOfRangeLimitWarning } from './OutOfRangeLimitWarning';
import { ValueWarning } from './ValueWarning';

export const WarningModalContent = ({ data }) => {
  if (!data) return null;

  return (
    <>
      {data.maxLimitForCompanyWarningData && <MaxLimitWarning data={data.maxLimitForCompanyWarningData} />}
      {data.limitOutOfRangeWarningData && <OutOfRangeLimitWarning data={data.limitOutOfRangeWarningData} />}
      {data.valueWarningData && <ValueWarning data={data.valueWarningData} />}
    </>
  );
};
