import { t } from 'i18next';
import { useContext } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';
import { formatBidValue } from 'shared/utils/offers';

export const OutOfRangeLimitWarning = ({ data }) => {
  const { offer } = useContext(OfferCreationContext);
  if (!data) return null;

  const { outOfRangeLimits } = data;
  const { min, max } = offer.initiation.auctionDetails?.sensitivityCalcParams?.limitRange;

  const formattedLimits = outOfRangeLimits
    ?.map((limit) => {
      return formatBidValue(limit, BID_INPUT_FIELDS.LIMIT, offer);
    })
    .join(', ');

  const formattedRange = [min, max]
    .map((limit) => {
      return formatBidValue(limit, BID_INPUT_FIELDS.LIMIT, offer);
    })
    .join(' - ');

  return (
    <>
      <p className="underline">{t('components.OutOfRangeLimitWarning.title')}</p>
      <p>{t('components.OutOfRangeLimitWarning.message', { formattedRange })}:</p>
      <span>{formattedLimits}</span>
    </>
  );
};
