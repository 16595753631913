import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';
import { formatBidValue } from 'shared/utils/offers';

export const MaxLimitWarning = ({ data }) => {
  const { t } = useTranslation();
  const { offer, auctionTitle, offerCompanyName } = useContext(OfferCreationContext);

  if (!data) return null;
  const { uniqueLimitsForAuctionByCompany } = data;

  const formattedLimits = uniqueLimitsForAuctionByCompany
    ?.map((limit) => {
      return formatBidValue(limit, BID_INPUT_FIELDS.LIMIT, offer);
    })
    .join(', ');

  const auctionType = offer?.initiation?.auctionDetails?.type;

  return (
    <>
      <p className="underline">{t('components.MaxThreeLimitWarning.title')}</p>
      <p>{t('components.MaxThreeLimitWarning.body', { companyName: offerCompanyName, auctionTitle })}:</p>
      <ul className="list-disc px-2">
        <li>
          {t(`components.MaxThreeLimitWarning.auctionTypes.${auctionType}`)}: {formattedLimits}
        </li>
      </ul>
    </>
  );
};
