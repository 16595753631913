import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { getOfferProposalDocumentHTML } from 'shared/apis';
import { AccountsStructures } from 'shared/const/accountsStructures';

import { useDownloadDividedAccountsExcel, useOfferStateUtils } from 'hooks/offers';

export const useViewAndSignSummary = () => {
  const { offer, offerId } = useContext(OfferCreationContext);
  const { t } = useTranslation();
  const { userId } = useContext(AuthContext);
  const { userCanSign, userHasSigned } = useOfferStateUtils({ offer });
  const { downloadDividedAccountsExcel } = useDownloadDividedAccountsExcel();
  const proposalDocumentContainer = useRef(null);
  const [showSignaturePadModal, setShowSignaturePadModal] = useState(false);
  const [showOfferSentModal, setShowOfferSentModal] = useState(false);

  const {
    data: htmlContent,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['offerProposalDocumentHTML', offerId],
    queryFn: () => getOfferProposalDocumentHTML(offerId),
    enabled: !!offerId,
  });

  const addSignButtonToHtml = useCallback(() => {
    const elementDedicatedToThisUser = document.getElementById(userId.toString());
    if (!elementDedicatedToThisUser) {
      return;
    }

    if (userHasSigned) {
      return;
    }

    const htmlButton = document.createElement('button');
    htmlButton.classList.add('button-success');
    htmlButton.classList.add('mb-2');
    htmlButton.innerHTML = t('components.SignatoriesTable.sign');
    htmlButton.id = 'signButton';

    elementDedicatedToThisUser.innerHTML = '';
    elementDedicatedToThisUser.appendChild(htmlButton);
  }, [t, userId, userHasSigned]);

  const enrichSignatureButton = useCallback(
    (node) => {
      const signatureHtmlButton = node.querySelector('#signButton');
      if (signatureHtmlButton) {
        signatureHtmlButton.onclick = () => {
          setShowSignaturePadModal(true);
        };
      }
    },
    [setShowSignaturePadModal],
  );

  const enrichDividedAccountingSpan = useCallback(
    (node) => {
      const chosenAccountsStructure = offer?.bankAccounts?.chosenType;
      if (chosenAccountsStructure === AccountsStructures.DIVIDED) {
        const dividedAccountingSpan = node.querySelector('#dividedAccounting');
        if (dividedAccountingSpan) {
          dividedAccountingSpan.onclick = () => downloadDividedAccountsExcel(offer);
          dividedAccountingSpan.classList.add('divided-accounting-span');
        }
      }
    },
    [downloadDividedAccountsExcel, offer],
  );

  // refetch the proposal document if the offer changes
  useEffect(() => {
    if (!offer) return;
    refetch();
  }, [offer, refetch, t, userId, userCanSign]);

  // add the sign button to the html content
  useEffect(() => {
    const node = proposalDocumentContainer.current;
    if (node && htmlContent) {
      addSignButtonToHtml();
      /* first add a listener to the signature button */
      enrichSignatureButton(node);

      /* then add a link for the divided accounting table if exists */
      enrichDividedAccountingSpan(node);
    }
  }, [proposalDocumentContainer, addSignButtonToHtml, enrichSignatureButton, enrichDividedAccountingSpan, htmlContent]);

  return {
    isLoading,
    isError,
    htmlContent,
    showSignaturePadModal,
    setShowSignaturePadModal,
    showOfferSentModal,
    setShowOfferSentModal,
    proposalDocumentContainer,
  };
};
