import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { investorTypesNoCertNeeded } from 'shared/const/investorTypes';

import { ExpirationDateInfo, UpdaterInfo } from 'components/CompanyDetails/ViewMode/CompanyInfo/AccreditedInvestorCertLi';
import { Tooltip } from 'components/UI/Tooltip';

export const AccreditedInvestorDetails = ({ contract }) => {
  const { t } = useTranslation();
  const { accreditedInvestorCert, investorType } = contract.company;

  if (investorTypesNoCertNeeded.includes(investorType)) {
    return (
      <Tooltip
        Trigger={<FontAwesomeIcon icon={faCheckCircle} className="text-success" />}
        Content={t('components.ContractRow.header.notApplicable', { investorType: t(`components.selectors.investorType.${investorType}`) })}
      />
    );
  }

  if (!accreditedInvestorCert) {
    return (
      <Tooltip Trigger={<FontAwesomeIcon icon={faWarning} className="text-warning" />} Content={t('components.ContractRow.header.noCertHeader')} />
    );
  }

  const { expirationDate, user, updatedAt } = accreditedInvestorCert;
  return (
    <>
      <ExpirationDateInfo expirationDate={expirationDate} /> <UpdaterInfo updatedAt={updatedAt} user={user} />
    </>
  );
};
