import { some } from 'lodash';
import { useCallback, useContext } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { useAwaitModal } from 'components/Offers/BidsAndAccountsModals';

import { getHighValueBids, getLowValueBids, getUniqLimitsForAuctionByCompany } from './utils';

export const useBidsAndAccountsPopUps = () => {
  const { offer, distinctLimitsForAuctionByCompanyData } = useContext(OfferCreationContext);

  const warningOpts = useAwaitModal();
  const splitConfirmOpts = useAwaitModal();

  const checkSplitsLimitsMismatch = useCallback(
    async (payload, bids) => {
      const { table } = payload;
      if (!table) {
        // no table, no mismatch (united accounts)
        return true;
      }

      if (!bids || !bids.length || bids.length === 1) {
        // no bids at all should not happen, but just in case
        // only one bids means only one limit, so no mismatch
        return true;
      }

      const splitsWithLimits = table.filter((row) => row.maxPercentageFromAuction);
      if (splitsWithLimits.length === 0) {
        // no limits set, no mismatch
        return true;
      }

      splitConfirmOpts.setData({
        table,
        bids,
        offer,
      });

      const confirm = await splitConfirmOpts.showModal();
      return confirm;
    },
    [splitConfirmOpts, offer],
  );

  const getMaxLimitsForCompanyWarningData = useCallback(
    (bids) => {
      if (!distinctLimitsForAuctionByCompanyData) {
        return null;
      }

      const uniqueLimitsForAuctionByCompany = getUniqLimitsForAuctionByCompany(distinctLimitsForAuctionByCompanyData, bids);
      if (uniqueLimitsForAuctionByCompany.length <= 3) {
        return null;
      }

      return {
        uniqueLimitsForAuctionByCompany,
        offer,
      };
    },
    [distinctLimitsForAuctionByCompanyData, offer],
  );

  const getValueWarningData = useCallback(
    (bids) => {
      const highValueBids = getHighValueBids(offer, bids);
      const lowValueBids = getLowValueBids(offer, bids);
      const hasAboveThresholdBids = some(highValueBids);
      const hasUnderLimitBids = some(lowValueBids);

      if (!hasAboveThresholdBids && !hasUnderLimitBids) {
        return null;
      }
      return {
        highValueBids,
        lowValueBids,
        type: offer?.initiation.auctionDetails.type,
        hasAboveThresholdBids,
        hasUnderLimitBids,
      };
    },
    [offer],
  );

  const getOutOfRangeLimits = useCallback((bids, min, max) => {
    const outOfRangeLimits = [];
    bids.reduce((acc, bid) => {
      if (bid.limit < min || bid.limit > max) {
        outOfRangeLimits.push(bid.limit);
      }
      return acc;
    }, outOfRangeLimits);

    return outOfRangeLimits;
  }, []);

  const getOutOfRangeLimitWarningData = useCallback(
    (bids) => {
      const { auctionDetails } = offer.initiation;
      if (!auctionDetails?.isAutoSensitivityCalc) {
        return null;
      }

      const limitRange = auctionDetails?.sensitivityCalcParams?.limitRange;
      if (!limitRange) {
        return null;
      }

      const outOfRangeLimits = getOutOfRangeLimits(bids, limitRange.min, limitRange.max);

      if (outOfRangeLimits.length === 0) {
        return null;
      }

      return {
        outOfRangeLimits,
      };
    },
    [getOutOfRangeLimits, offer.initiation],
  );

  const checkWarnings = useCallback(
    async (bids) => {
      const valueWarningData = getValueWarningData(bids);
      const maxLimitForCompanyWarningData = getMaxLimitsForCompanyWarningData(bids);
      const limitOutOfRangeWarningData = getOutOfRangeLimitWarningData(bids);

      warningOpts.setData({
        valueWarningData,
        maxLimitForCompanyWarningData,
        limitOutOfRangeWarningData,
      });

      if (valueWarningData || maxLimitForCompanyWarningData || limitOutOfRangeWarningData) {
        const confirm = await warningOpts.showModal();
        return confirm;
      }

      return true;
    },
    [getValueWarningData, getMaxLimitsForCompanyWarningData, getOutOfRangeLimitWarningData, warningOpts],
  );

  return {
    warningOpts,
    splitConfirmOpts,
    checkSplitsLimitsMismatch,
    checkWarnings,
  };
};
