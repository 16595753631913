export const investorTypes = {
  CLASSIFIED_CORPORATION: 'CLASSIFIED_CORPORATION',
  UNDERWRITER: 'UNDERWRITER',
  INSURANCE_AGENCY: 'INSURANCE_AGENCY',
  CORPORATION_WITH_MORE_THAN_MINIMUM: 'CORPORATION_WITH_MORE_THAN_MINIMUM',
  PENSION_FUND: 'PENSION_FUND',
  MUTUAL_FUND: 'MUTUAL_FUND',
  PORTFOLIO_MANAGER: 'PORTFOLIO_MANAGER',
  BANK: 'BANK',
  ETF_FUND: 'ETF_FUND',
  CORPORATION_OWNED_BY_CLASSIFIED_INVESTOR: 'CORPORATION_OWNED_BY_CLASSIFIED_INVESTOR',
  CAPITAL_FUND: 'CAPITAL_FUND',
  COMPANY_WITH_INVESTMENTS_MARKETING_LICENSE: 'COMPANY_WITH_INVESTMENTS_MARKETING_LICENSE',
  STOCK_EXCHANGE_MEMBER: 'STOCK_EXCHANGE_MEMBER',
  OTHER: 'OTHER',
};

export const investorTypesNoCertNeeded = [
  investorTypes.BANK,
  investorTypes.INSURANCE_AGENCY,
  investorTypes.PENSION_FUND,
  investorTypes.ETF_FUND,
  investorTypes.MUTUAL_FUND,
  investorTypes.PORTFOLIO_MANAGER,
];

export const defaultInvestorType = investorTypes.CLASSIFIED_CORPORATION;
